@import 'settings/index';
@import 'tools/index';
@import 'index';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slick-prev:before, .slick-next:before {
  color: green !important
}

.slick-list, .slick-track { 
  touch-action: pan-y; 
}
