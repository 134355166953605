.modal {
  background-color: rgba(0,0,0,0.3);
  transition: all 0.3s;

  .modal-dialog {
    position: fixed;
    padding: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.modal-content{
  border: none;
  box-shadow: -1px 2px 16px 3px rgba(100, 100, 100, 0.28);


  .modal-header{
    background-color: #1166d8;
    box-shadow: 3px 3px 14px 6px rgba(163, 178, 195, 0.35);
    z-index: 99;
    border: none;

    .modal-title{
      color: #fff;
    }

    .close {
      color: #fff;
      transition: all 0.3s;
    }
  }

  .modal-footer{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-top: none;
    box-shadow: 0 0 2rem 0 rgba(163, 178, 195, 0.35);
    z-index: 99;
  }
}