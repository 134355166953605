// colorhexa.com
.btn {
  transition: all 0.2s;
  border-radius: 5px;
}

.btn-primary, .btn-success, .btn-warning, .btn-danger{
  border: none;
}

.btn-primary {
  background-color: #1166D8;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #2875db;
}


.btn-success.disabled.focus, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled].focus, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
  background-color: #9fc1ef;
  border: none;
}


.btn-action{
  width: 25px;
  height: 25px;
  padding: 0;
  line-height: 0;
  text-align: center;
  border-radius: 5px;
  margin: 2px;
}

.btn-plus{
  height: 25px;
  margin: 0%;
  padding-left: 0;
}
.btn-plus i{
  height: 25px;
  margin: -5px;
  padding-left: 0;
}

// ccustom button outline
.btn-packages .btn-outline-primary,.btn-packages .btn-primary {
  border: 1px solid #1166D8;
}
.btn-packages .btn-outline-primary:hover {
  background-color: #1166D8;
  border: 1px solid #1166D8;
}
.btn-packages .btn-outline-primary:hover span {
  color: #fff !important;
}