.navbar {
  background-color: transparent;
  box-shadow: none;
  transition: all 0.2s;
}
/* body{
  font-family: "Muli", Arial, sans-serif;
} */

.navbar:not(.white) .navbar-nav .nav-link{
  color: #fff !important;
}

.navbar:not(.white) .nav-icon{
  filter: brightness(0) invert(1);
}

.navbar:not(.white) .navbar-toggler{
  border-color: #fff;
}

.navbar:not(.white) .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar.white{
  background-color: #fff;
  box-shadow: 0px 3px 10px 2px rgba(50,50,50,0.2);
}

p {
  font-size: 0.9rem;
  line-height: 2;
}

h1,h2,h3,h4,h5{
  font-weight: bold;
  color: #1A4072;
  line-height: 1.5;
}

.card {
  box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.1)
}

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;  
}

.py-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;  
}

@media (min-width: 768px) {
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;  
  }
  .py-md-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;  
  }
}

.sub-title{
  color: #09A599;
  font-weight: 900;
  font-size: 0.8rem;
  letter-spacing: 0px;
}
