@import '~bootstrap-css-only/css/bootstrap.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'styles/main';


.swal2-container {
  z-index: 9999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.glow-input {
  -webkit-transition: all 0.10s ease-in-out;
  -moz-transition: all 0.10s ease-in-out;
  -ms-transition: all 0.10s ease-in-out;
  -o-transition: all 0.10s ease-in-out;
  transition: all 0.10s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #DDDDDD;
}
 
.glow-input:focus {
  box-shadow: 0 0 10px #09A599;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #09A599;
}

li {
  margin: 1.3em 0 1em;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}

li ol > li {
  margin: 0;
  margin-bottom: 15px;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

/* ---------- Container for the orbiting circles animation ---------- */
#circle-orbit-container {
  position: relative;
  top: 0px;
  left:0px;
  min-height: 400px;
  max-height: 500px;
  width: 500px;
  max-width: 100%;
}

/* ---------- Inner orbit - This is the circles closest to the central point ---------- */
#inner-orbit {
  position: absolute;
  top: 75px;
  left: 75px;
  width: 200px;
  height: 200px;
  border: 2px #1166d8 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 10s linear infinite;
  animation: spin-right 10s linear infinite;
}

/* ---------- Repeating styles for the inner orbiting circles ---------- */
.inner-orbit-cirlces {
  position: absolute;
  top: 62px;
  left: -6px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #9F98E6;
}

/* ---------- Middle orbit - This is the circles second closest to the central point ---------- */
#middle-orbit {
  position: absolute;
  top: 35px;
  left: 35px;
  width: 275px;
  height: 275px;
  border: 2px #1166d8 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 15s linear infinite;
  animation: spin-right 15s linear infinite;
}

/* ---------- Repeating styles for the inner orbiting circles ---------- */
.middle-orbit-cirlces {
  position: absolute;
  top: 25px;
  left: 17px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: #1166d8;
}

/* ---------- Outer orbit - This is the circles furthest away from the central point ---------- */
#outer-orbit {
  position: absolute;
  top: 0;
  left: 0;
  width: 344px;
  height: 344px;
  border: 2px #1166d8 dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 20s linear infinite;
  animation: spin-right 20s linear infinite;
}

/* ---------- Repeating styles for the outer orbiting circles ---------- */
.outer-orbit-cirlces {
  position: absolute;
  top: -17px;
  left: 125px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #1166d8;
}

/* ---------- Animation ---------- */
@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}