.products-page{
	.sticky {
	  position: sticky !important;
	  position: -webkit-sticky !important;
	  z-index: 2;
	}

	.row,
	.sticky {
	  display: flex;
	  align-items: center;
	  background-color: white;
	  border-bottom: 1px solid #eee;
	  box-sizing: border-box;
	}
}