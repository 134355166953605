html, body{
  height: 100%;
  width: 100%;
}

#root{
  height: 100%;
  width: 100%;
}

html, html a, body {
  -webkit-font-smoothing: antialiased;
}

a {
  transition: all 0.3s ease-in-out;
}

body {
  font-family: $font-primary;
  font-size: 14px;
  color: $default-text-color;
  line-height: 1.2;
  letter-spacing: 0;
  overflow-x: hidden;
}
*:focus {
  outline: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: bold;
  color: #0a4fab;

  a {
    font-family: $font-secondary;
  }

  small {
    font-weight: 300;
    color: lighten($default-dark, 5%);
  }
}

h5 {
  font-size: 1rem;
}

p {
  font-family: $font-primary;
  line-height: 1.9;
}

.lead {
  font-size: 18px;
}

ul {
  margin-bottom: 0;
}

a {
  color: $default-primary;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($default-primary, 10%);
  }

  &:focus {
    outline: none;
  }

  &.text-gray {
    &:hover,
    &:focus,
    &.active {
      color: $default-dark !important;
    }
  }
}

:focus {
  outline: none;
}

hr {
  border-top: 1px solid $border-color;
}

.zero-padding-left{
  padding-left: 0;
}

.zero-padding-right{
  padding-left: 0;
}

.card {
  box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
  padding: 20px;
  // padding-right: 30px;
  // margin-right: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border: none;
  border-radius: 5px;
  background: #fff;
  overflow: visible;
}


.main-content {
  background-color: #F3F4F9;
}

.btn-light {
  color: #212529;
  background-color: white;
  border: none;
}

.search_mark {
  background-color: yellow
}


.home-slide-overlay{
  background-color: transparent;
  background-image: linear-gradient(90deg, #072c82 49%, rgba(7,44,130,0.01) 75%);
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .home-img-small-md{
    max-height: 300px !important;
  }
}


.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}



.page-link {
  color: #1AA5B8
}

.page-item.active .page-link {
  background-color: #1AA5B8;
  border-color: #00d400;
}


.gradient-bg{
  background: linear-gradient(270deg, #2bd0b4, #f2cf12, #6663f5);
  background-size: 600% 600%;

  -webkit-animation: slidegradient 30s ease infinite;
  -moz-animation: slidegradient 30s ease infinite;
  -o-animation: slidegradient 30s ease infinite;
  animation: slidegradient 30s ease infinite;
}

@-webkit-keyframes slidegradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes slidegradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes slidegradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes slidegradient { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.bg-linear-1{
  background-image: linear-gradient( 109.7deg,  rgba(101,204,184,1) 12.9%, rgba(109,236,185,1) 101.5% );
}
.bg-linear-2{
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(67,144,233,1) 0%, rgba(78,226,198,1) 90.1% );
}
.bg-linear-3{
  background: linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114));
}
.bg-linear-4{
  background: linear-gradient(to right, #654ea3, #eaafc8);

}

.header-container{
  background-color: #fff;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding-right: 1.5em
}

.text-dim{
  color: #8B9BB1
}

.table-default{
  background-color: #fff;
  border-radius: 5px;
  border-top: none;

  color: #333;

  tr>th{
    border: none;
    border-bottom: 3px solid #F5F7FB;
  }
  tr>td{
    border-bottom: 1px solid rgb(245, 247, 251);
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
  }
}

.col-form-label{
  font-size: 12px;
  padding-bottom: 0;
}


/*Checkbox: by harrytraynor Start*/
.cbx {
  position: relative;
  top: 1px;
  width: 17px;
  height: 17px;
  border: 1px solid #c8ccd4;
  border-radius: 3px;
  vertical-align: middle;
  transition: background 0.1s ease;
  cursor: pointer;
}

.cbx:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 11px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}
.cbx.disabled{
  background-color: #E9ECEF;
}
.lbl {
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
}
.cbx_input:checked ~ .cbx {
  border-color: transparent;
  background: #1166D8;
  animation: jelly 0.6s ease;
}
.cbx_input:checked ~ .cbx:after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

@-moz-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-o-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
/*Checkbox: End*/


/*Loading: start*/
.animated-loader {
  width: 40px;
  height: 40px;
}
.animated-loader:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  // margin: 8px;
  border-radius: 50%;
  border: 6px solid #09A599;
  border-color: #09A599 transparent #09A599 transparent;
  animation: animated-loader 0.5s linear infinite;
}
.animated-loader-white.animated-loader:after {
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
}
@keyframes animated-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// custom background react-select
.select__control.select__control--is-disabled {
  background-color: #ebedf0;
  border: 1px solid #ced4da;

  .css-107lb6w-singleValue {
    color: #495057;
  }
}
/*Loading: end*/