.post-transition-enter {
  opacity: 0.01;
}

.post-transition-enter.post-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.post-transition-leave {
  opacity: 1;
}

.post-transition-leave.post-transition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.post-transition-appear {
  opacity: 0.01;
}

.post-transition-appear.post-transition-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
